import * as CamEl from '@auzmartist/cam-el'
(window as any).externals = CamEl

import AmAnnotation from './am-annotation.vue'
import AmBanner from './am-banner.vue'
import AmCollapsible from './am-collapsible.vue'
import amCollapsible from './amCollapsible'
import AmList from './am-ul.vue'
import AmLi from './am-li.vue'

export default (app) => {
	app.component('am-annotation', AmAnnotation)
	app.component('am-banner', AmBanner)
	app.component('am-collapsible', AmCollapsible)
	app.component('am-ul', AmList)
	app.component('am-li', AmLi)
}

export {amCollapsible}