import {createWebHistory, createRouter} from 'vue-router'

const routes = [
	{path: '/', component: () => import('src/views/Home.vue')},
	{path: '/bills', component: () => import('src/components/bill/el-bill-dashboard.vue')},
	{path: '/bills/:billId', component: () => import('src/components/bill/el-bill-viewer.vue'), props: true},
	{path: '/members', component: () => import('src/components/member/el-member-dashboard.vue')},
	{path: '/members/:memberId', component: () => import('src/components/member/el-member-viewer.vue'), props: true},
	{
		path: '/:chamber/committees/:code',
		component: () => import('src/components/committee/el-committee-viewer.vue'),
		props: true,
	},
	{
		path: '/:chamber/session/:session/roll-call/:rollCall',
		component: () => import('src/views/Contact.vue'), // TODO
	},
	{path: '/about', component: () => import('src/views/About.vue')},
	{path: '/privacy-policy', component: () => import('src/views/Privacy.vue')},
	{path: '/contact', component: () => import('src/views/Contact.vue')},
	{path: '/news', component: () => import('src/views/Roadmap.vue')},
	{path: '/profile', component: () => import('src/views/Profile.vue')},
	{path: '/type', component: () => import('src/views/Typeography.vue')},
]

export default createRouter({
	history: createWebHistory(),
	routes,
})