<template>
	<ul class="am-ul">
		<slot></slot>
	</ul>
</template>

<script lang="ts">
export default {
	name: 'am-ul',
}
</script>

<style lang="stylus" scoped>
.am-ul {
	
}
</style>