<template>
	<div class="am-annotation">
		<div class="annotation">
			<div>
				<aside>{{label}}</aside>
				<i class="lni lni-question-circle"/>
			</div>
		</div>
		<div class="content">
			<slot></slot>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'am-annotation',
	props: {
		label: {type: String, default: 'Annotation'}
	},
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.am-annotation {
	position: relative
	flexXY(flex-start, center)
	padding: 8px 0
	flex-wrap: nowrap
	& > * {
		align-self: auto
	}
	.annotation {
		position: relative
		wh(2em, 0)
		color: darken($c-primary-1, 30%)
		& > div {
			flexXY(flex-start, center)
			absPos(50%, auto, auto, 50%)
			transform: translateX(-50%) translateY(-50%) rotate(-90deg)
			& > *:not(:first-child) {
				margin-left: 12px
			}
		}
		aside {
			emph()
			font-size: 0.85em
		}
		aside + i {
			transform: rotate(90deg)
		}
	}
	.content {
		padding: 4px 0
		padding-left: 24px
		border-left: 2px solid #ddd
		flex: 1 1
	}
}
</style>