import { render } from "./App.vue?vue&type=template&id=b49c1526&scoped=true"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"

import "./App.vue?vue&type=style&index=0&id=b49c1526&lang=css"
import "style/app.styl?vue&type=style&index=1&lang=stylus"
import "./App.vue?vue&type=style&index=2&id=b49c1526&lang=stylus&scoped=true"
script.render = render
script.__scopeId = "data-v-b49c1526"

export default script