import { CURRENT_CONGRESS } from 'domain/Constants'
import {computed, Ref} from 'vue'
import {Store, createStore, useStore} from 'vuex'

import bill, {BillState} from './bill.module'
import member, {MemberState} from './member.module'
import vote, { VoteState } from './vote.module'
import { Profile } from 'domain/schema/Profile.model'

export interface State {
  profile: Profile,
  bill: BillState,
  member: MemberState,
  vote: VoteState,
}

export default createStore<State>({
  modules: {
    bill,
    member,
    vote,
    prefs: {
      namespaced: true,
      state: {congress: CURRENT_CONGRESS},
      mutations: {
        setCongress: (s, congress) => s.congress = congress,
      }
    },
  },
  state: () => ({
    profile: {
      last_sign_in: null,
      create_date: null,
      zip: null,
    } as Profile,
  }) as State,
  mutations: {
    saveProfile: (state, profile) => state.profile = profile,
  },
})

export function stored<T, S>(getter: (store: Store<S>) => T, storer: (store: Store<S>) => void): Ref<T> {
  const store = useStore<S>()
  if(!getter(store)) storer(store)
  return computed(() => getter(store))
}
