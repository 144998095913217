export const CONGRESSES = [
	{'congress': 80,  'start': new Date('January 3, 1947'),  'end': new Date('December 31, 1948')},
	{'congress': 81,  'start': new Date('January 3, 1949'),  'end': new Date('January 2, 1951')},
	{'congress': 82,  'start': new Date('January 3, 1951'),  'end': new Date('July 7, 1952')},
	{'congress': 83,  'start': new Date('January 3, 1953'),  'end': new Date('December 2, 1954')},
	{'congress': 84,  'start': new Date('January 5, 1955'),  'end': new Date('July 27, 1956')},
	{'congress': 85,  'start': new Date('January 3, 1957'),  'end': new Date('August 24, 1958')},
	{'congress': 86,  'start': new Date('January 7, 1959'),  'end': new Date('September 1, 1960')},
	{'congress': 87,  'start': new Date('January 3, 1961'),  'end': new Date('October 13, 1962')},
	{'congress': 88,  'start': new Date('January 9, 1963'),  'end': new Date('October 3, 1964')},
	{'congress': 89,  'start': new Date('January 4, 1965'),  'end': new Date('October 22, 1966')},
	{'congress': 90,  'start': new Date('January 10, 1967'), 'end': new Date('October 14, 1968')},
	{'congress': 91,  'start': new Date('January 3, 1969'),  'end': new Date('January 2, 1971')},
	{'congress': 92,  'start': new Date('January 21, 1971'), 'end': new Date('October 18, 1972')},
	{'congress': 93,  'start': new Date('January 3, 1973'),  'end': new Date('December 20, 1974')},
	{'congress': 94,  'start': new Date('January 14, 1975'), 'end': new Date('October 1, 1976')},
	{'congress': 95,  'start': new Date('January 4, 1977'),  'end': new Date('October 15, 1978')},
	{'congress': 96,  'start': new Date('January 15, 1979'), 'end': new Date('December 16, 1980')},
	{'congress': 97,  'start': new Date('January 5, 1981'),  'end': new Date('December 23, 1982')},
	{'congress': 98,  'start': new Date('January 3, 1983'),  'end': new Date('October 12, 1984')},
	{'congress': 99,  'start': new Date('January 3, 1985'),  'end': new Date('October 18, 1986')},
	{'congress': 100, 'start': new Date('January 6, 1987'),  'end': new Date('October 22, 1988')},
	{'congress': 101, 'start': new Date('January 3, 1989'),  'end': new Date('October 28, 1990')},
	{'congress': 102, 'start': new Date('January 3, 1991'),  'end': new Date('October 9, 1992')},
	{'congress': 103, 'start': new Date('January 5, 1993'),  'end': new Date('December 1, 1994')},
	{'congress': 104, 'start': new Date('January 4, 1995'),  'end': new Date('October 4, 1996')},
	{'congress': 105, 'start': new Date('January 7, 1997'),  'end': new Date('December 19, 1998')},
	{'congress': 106, 'start': new Date('January 6, 1999'),  'end': new Date('December 15, 2000')},
	{'congress': 107, 'start': new Date('January 3, 2001'),  'end': new Date('November 22, 2002')},
	{'congress': 108, 'start': new Date('January 7, 2003'),  'end': new Date('December 8, 2004')},
	{'congress': 109, 'start': new Date('January 4, 2005'),  'end': new Date('December 9, 2006')},
	{'congress': 110, 'start': new Date('January 4, 2007'),  'end': new Date('January 3, 2009')},
	{'congress': 111, 'start': new Date('January 6, 2009'),  'end': new Date('December 22, 2010')},
	{'congress': 112, 'start': new Date('January 5, 2011'),  'end': new Date('January 3, 2013')},
	{'congress': 113, 'start': new Date('January 3, 2013'),  'end': new Date('December 16, 2014')},
	{'congress': 114, 'start': new Date('January 6, 2015'),  'end': new Date('January 3, 2017')},
	{'congress': 115, 'start': new Date('January 3, 2017'),  'end': new Date('January 3, 2019')},
	{'congress': 116, 'start': new Date('January 3, 2019'),  'end': new Date('January 3, 2021')},
	{'congress': 117, 'start': new Date('January 3, 2021'),  'end': new Date('January 3, 2023')},
	{'congress': 118, 'start': new Date('January 3, 2023'),  'end': new Date('January 3, 2025')},
]

export const CURRENT_CONGRESS: number = CONGRESSES.find((c) => c.start < new Date() && new Date() < c.end).congress

export const STATE_CODES = ["AL","AK","AZ","AR","CA","CO","CT","DE","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY"]
export const TERRITORY_CODES = ["AS","DC","FM","GU","MH","MP","PW","PR","VI"]

export const State = {
	AL: {name: 'Alabama', iso: 'US-AL', code: 'AL', ansi: '01', usps: 'AL', uscg: 'AL', redistrict_year: '2016'},
	AK: {name: 'Alaska', iso: 'US-AK', code: 'AK', ansi: '02', usps: 'AK', uscg: 'AK', redistrict_year: '2016'},
	AZ: {name: 'Arizona', iso: 'US-AZ', code: 'AZ', ansi: '04', usps: 'AZ', uscg: 'AZ', redistrict_year: '2016'},
	AR: {name: 'Arkansas', iso: 'US-AR', code: 'AR', ansi: '05', usps: 'AR', uscg: 'AR', redistrict_year: '2016'},
	CA: {name: 'California', iso: 'US-CA', code: 'CA', ansi: '06', usps: 'CA', uscg: 'CF', redistrict_year: '2016'},
	CO: {name: 'Colorado', iso: 'US-CO', code: 'CO', ansi: '08', usps: 'CO', uscg: 'CL', redistrict_year: '2016'},
	CT: {name: 'Connecticut', iso: 'US-CT', code: 'CT', ansi: '09', usps: 'CT', uscg: 'CT', redistrict_year: '2016'},
	DE: {name: 'Delaware', iso: 'US-DE', code: 'DE', ansi: '10', usps: 'DE', uscg: 'DL', redistrict_year: '2016'},
	FL: {name: 'Florida', iso: 'US-FL', code: 'FL', ansi: '12', usps: 'FL', uscg: 'FL', redistrict_year: '2016'},
	GA: {name: 'Georgia', iso: 'US-GA', code: 'GA', ansi: '13', usps: 'GA', uscg: 'GA', redistrict_year: '2016'},
	HI: {name: 'Hawaii', iso: 'US-HI', code: 'HI', ansi: '15', usps: 'HI', uscg: 'HA', redistrict_year: '2016'},
	ID: {name: 'Idaho', iso: 'US-ID', code: 'ID', ansi: '16', usps: 'ID', uscg: 'ID', redistrict_year: '2016'},
	IL: {name: 'Illinois', iso: 'US-IL', code: 'IL', ansi: '17', usps: 'IL', uscg: 'IL', redistrict_year: '2016'},
	IN: {name: 'Indiana', iso: 'US-IN', code: 'IN', ansi: '18', usps: 'IN', uscg: 'IN', redistrict_year: '2016'},
	IA: {name: 'Iowa', iso: 'US-IA', code: 'IA', ansi: '19', usps: 'IA', uscg: 'IA', redistrict_year: '2016'},
	KS: {name: 'Kansas', iso: 'US-KS', code: 'KS', ansi: '20', usps: 'KS', uscg: 'KA', redistrict_year: '2016'},
	KY: {name: 'Kentucky', iso: 'US-KY', code: 'KY', ansi: '21', usps: 'KY', uscg: 'KY', redistrict_year: '2016'},
	LA: {name: 'Louisiana', iso: 'US-LA', code: 'LA', ansi: '22', usps: 'LA', uscg: 'LA', redistrict_year: '2016'},
	ME: {name: 'Maine', iso: 'US-ME', code: 'ME', ansi: '23', usps: 'ME', uscg: 'ME', redistrict_year: '2016'},
	MD: {name: 'Maryland', iso: 'US-MD', code: 'MD', ansi: '24', usps: 'MD', uscg: 'MD', redistrict_year: '2016'},
	MA: {name: 'Massachusetts', iso: 'US-MA', code: 'MA', ansi: '25', usps: 'MA', uscg: 'MS', redistrict_year: '2016'},
	MI: {name: 'Michigan', iso: 'US-MI', code: 'MI', ansi: '26', usps: 'MI', uscg: 'MC', redistrict_year: '2016'},
	MN: {name: 'Minnesota', iso: 'US-MN', code: 'MN', ansi: '27', usps: 'MN', uscg: 'MN', redistrict_year: '2016'},
	MS: {name: 'Mississippi', iso: 'US-MS', code: 'MS', ansi: '28', usps: 'MS', uscg: 'MI', redistrict_year: '2016'},
	MO: {name: 'Missouri', iso: 'US-MO', code: 'MO', ansi: '29', usps: 'MO', uscg: 'MO', redistrict_year: '2016'},
	MT: {name: 'Montana', iso: 'US-MT', code: 'MT', ansi: '30', usps: 'MT', uscg: 'MT', redistrict_year: '2016'},
	NE: {name: 'Nebraska', iso: 'US-NE', code: 'NE', ansi: '31', usps: 'NE', uscg: 'NB', redistrict_year: '2016'},
	NV: {name: 'Nevada', iso: 'US-NV', code: 'NV', ansi: '32', usps: 'NV', uscg: 'NV', redistrict_year: '2016'},
	NH: {name: 'New Hampshire', iso: 'US-NH', code: 'NH', ansi: '33', usps: 'NH', uscg: 'NH', redistrict_year: '2016'},
	NJ: {name: 'New Jersey', iso: 'US-NJ', code: 'NJ', ansi: '34', usps: 'NJ', uscg: 'NJ', redistrict_year: '2016'},
	NM: {name: 'New Mexico', iso: 'US-NM', code: 'NM', ansi: '35', usps: 'NM', uscg: 'NM', redistrict_year: '2016'},
	NY: {name: 'New York', iso: 'US-NY', code: 'NY', ansi: '36', usps: 'NY', uscg: 'NY', redistrict_year: '2016'},
	NC: {name: 'North Carolina', iso: 'US-NC', code: 'NC', ansi: '37', usps: 'NC', uscg: 'NC', redistrict_year: '2020'},
	ND: {name: 'North Dakota', iso: 'US-ND', code: 'ND', ansi: '38', usps: 'ND', uscg: 'ND', redistrict_year: '2016'},
	OH: {name: 'Ohio', iso: 'US-OH', code: 'OH', ansi: '39', usps: 'OH', uscg: 'OH', redistrict_year: '2016'},
	OK: {name: 'Oklahoma', iso: 'US-OK', code: 'OK', ansi: '40', usps: 'OK', uscg: 'OK', redistrict_year: '2016'},
	OR: {name: 'Oregon', iso: 'US-OR', code: 'OR', ansi: '41', usps: 'OR', uscg: 'OR', redistrict_year: '2016'},
	PA: {name: 'Pennsylvania', iso: 'US-PA', code: 'PA', ansi: '42', usps: 'PA', uscg: 'PA', redistrict_year: '2018'},
	RI: {name: 'Rhode Island', iso: 'US-RI', code: 'RI', ansi: '44', usps: 'RI', uscg: 'RI', redistrict_year: '2016'},
	SC: {name: 'South Carolina', iso: 'US-SC', code: 'SC', ansi: '45', usps: 'SC', uscg: 'SC', redistrict_year: '2016'},
	SD: {name: 'South Dakota', iso: 'US-SD', code: 'SD', ansi: '46', usps: 'SD', uscg: 'SD', redistrict_year: '2016'},
	TN: {name: 'Tennessee', iso: 'US-TN', code: 'TN', ansi: '47', usps: 'TN', uscg: 'TN', redistrict_year: '2016'},
	TX: {name: 'Texas', iso: 'US-TX', code: 'TX', ansi: '48', usps: 'TX', uscg: 'TX', redistrict_year: '2016'},
	UT: {name: 'Utah', iso: 'US-UT', code: 'UT', ansi: '49', usps: 'UT', uscg: 'UT', redistrict_year: '2016'},
	VT: {name: 'Vermont', iso: 'US-VT', code: 'VT', ansi: '50', usps: 'VT', uscg: 'VT', redistrict_year: '2016'},
	VA: {name: 'Virginia', iso: 'US-VA', code: 'VA', ansi: '51', usps: 'VA', uscg: 'VA', redistrict_year: '2016'},
	WA: {name: 'Washington', iso: 'US-WA', code: 'WA', ansi: '53', usps: 'WA', uscg: 'WN', redistrict_year: '2016'},
	WV: {name: 'West Virginia', iso: 'US-WV', code: 'WV', ansi: '54', usps: 'WV', uscg: 'WV', redistrict_year: '2016'},
	WI: {name: 'Wisconsin', iso: 'US-WI', code: 'WI', ansi: '55', usps: 'WI', uscg: 'WS', redistrict_year: '2016'},
	WY: {name: 'Wyoming', iso: 'US-WY', code: 'WY', ansi: '56', usps: 'WY', uscg: 'WY', redistrict_year: '2016'},
}

export enum Chamber {
	House = 'House',
	Senate = 'Senate',
}

export const Party = {
	D: 'Democrat',
	R: 'Republican',
	I: 'Independent',
}

export default {
	CURRENT_CONGRESS
}

