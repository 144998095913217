<template>
	<div class="el-welcome">
		<div>
			<br><br><br>
			<h3 class="center">Welcome to the Ellis Project</h3>
			<hr>
			<p class="center">Ellis is a digital tool to help you become better informed.</p>
			<p class="center">Government is complicated. Ellis is here to help.</p>
			<p class="center">Ellis is all about primary sources. No journalistic "spin." Pure facts.</p>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'el-welcome',
}
</script>

<style lang="stylus" scoped>
@require '~style/mixins.styl'
@require '~style/variables.styl'

.el-welcome {
	flexXY(center)

}
</style>