import { Bill } from 'domain/schema/Bill.model'
import {fetchHTML, fetchJson, fetchResponse} from 'lib/http/Http'
import {Profile} from 'domain/schema/Profile.model'
import { CongressionalDistrict } from 'domain/schema/CongressionalDistrict'

interface UserApi {
	signIn: ({token: string}) => Promise<Profile>,
	updateProfile: (fields: Record<string, any>) => Promise<any>,
}

interface BillApi {
	fetch: (billId: string) => Promise<Bill>,
	get: (billId: string) => Promise<Bill>,
	getMany: (billIds: string[]) => Promise<Bill[]>,
	getText: (billId: string) => Promise<any>, // XML
	search: (query: string) => Promise<Bill[]>,
	favorite: (billId: string, isCreate: boolean) => Promise<any>,
	tag: (tag: {entity: string, id: string, tag: string}) => Promise<any>,
	deleteTag: (tag: {entity: string, id: string, tag: string}) => Promise<any>,
}

interface MemberApi {
	[key: string]: any
}

interface MappingApi {
	districts: () => Promise<Record<string, CongressionalDistrict>>
}

export interface EllisApi {
	fetchJson: (url: string, options: any) => Promise<any>,
	user: UserApi,
	bill: BillApi,
	member: MemberApi,
	vote: any,
	committee: any,
	mapping: MappingApi,
	proPub: (url: string) => Promise<any>,
}

export default (function EllisService(baseUrl) {
	const BASE_URL = `${baseUrl}/api/v1`
	const user = {
		signIn: ({token}) => fetchJson(`${BASE_URL}/user/signin`, {method: 'POST', headers: {Authorization: `Bearer ${token}`}}),
		updateProfile: (fields) => fetchJson(`${BASE_URL}/user/profile`, {method: 'PATCH', body: JSON.stringify(fields)}),
	}

	const bill = {
		fetch: (billId) => fetchJson(`${BASE_URL}/bill/${billId}`),
		get: (billId) => fetchJson(`${BASE_URL}/bill/${billId}`),
		getMany: (billIds) => billIds?.length > 0 ? fetchJson(`${BASE_URL}/bills?ids=${billIds.join(',')}`) : Promise.resolve(null),
		getText: (billId) => fetchHTML(`${BASE_URL}/bill/${billId}/text`),
		search: (query) => fetchJson(`${BASE_URL}/bill/search?q=${query}`),
		favorite: (billId, isCreate = true) => fetchResponse(`${BASE_URL}/bill/${billId}/favorite/`, {
			method: isCreate ? 'POST' : 'DELETE',
		}),
		tag: ({entity, id, tag}) => fetchJson(`${BASE_URL}/bill/tag`, {
			method: 'POST',
			body: JSON.stringify({entity, id, tag: tag}),
		}),
		deleteTag: ({entity, id, tag}) => fetchJson(`${BASE_URL}/bill/tag`, {
			method: 'DELETE',
			body: JSON.stringify({entity, id, tag}),
		}),
	}

	const member = {
		body: (congress, chamber) => fetchJson(`${BASE_URL}/member/${congress}/${chamber}`),
		fetch: (memberId) => fetchJson(`${BASE_URL}/member/${memberId}`),
		get: (memberId) => fetchJson(`${BASE_URL}/member/${memberId}`),
		getByName: (first, last) => fetchJson(`${BASE_URL}/member?first=${first}&last=${last}`),
		favorite: (memberId) => fetchJson(`${BASE_URL}/member/${memberId}/favorite/`, {method: 'POST'}),
		unfavorite: (memberId) => fetchJson(`${BASE_URL}/member/${memberId}/favorite/`, {method: 'DELETE'}),
		dwNominate: (congress, chamber) => fetchJson(`${BASE_URL}/member/dw-nominate/${congress}/${chamber}`),
	}

	const vote = {
		recent: (chamber) => fetchJson(`${BASE_URL}/vote/recent/${chamber}`),
		rollCall: (congress, chamber, session, roll_call) => fetchJson(`${BASE_URL}/vote/${congress}/${chamber}/${session}/${roll_call}`),
		rollCallByKey: (voteUrlKey) => fetchJson(`${BASE_URL}/vote/${voteUrlKey.replace(/\./g, '/')}`),
	}

	const committee = {
		get: (congress, chamber, id) => fetchJson(`${BASE_URL}/${congress}/${chamber}/committee/${id}`),
	}

	const mapping = {
		districts: () => fetchJson(`${BASE_URL}/mapping/districts`),
	}

	const proPub = (uri) => fetchJson(`${BASE_URL}/propublica?uri=${uri}`)

	return {
		fetchJson,
		user,
		bill,
		'bill/get': (id) => fetchJson(`${BASE_URL}/bill/${id}`),
		'bills': (ids?: number[]) => ids.length > 0 ? fetchJson(`${BASE_URL}/bills?ids=${ids.join(',')}`) : Promise.resolve([]),
		'bill/text': (id) => fetchHTML(`${BASE_URL}/bill/${id}/text`),
		'bill/favorite:POST': (id) => fetchResponse(`${BASE_URL}/bill/${id}/favorite`, {method: 'POST'}),
		'bill/favorite:DELETE': (id) => fetchResponse(`${BASE_URL}/bill/${id}/favorite`, {method: 'DELETE'}),
		'tag:POST': (entity, id, tag) => fetchJson(`${BASE_URL}/tag`, {
			method: 'POST',
			body: JSON.stringify({entity, id, tag})
		}),
		'tag:DELETE': (entity, id, tag) => fetchJson(`${BASE_URL}/tag`, {
			method: 'DELETE',
			body: JSON.stringify({entity, id, tag})
		}),

		'member/get': (id) => fetchJson(`${BASE_URL}/member/${id}`),
		'member/favorite:POST': (id) => fetchResponse(`${BASE_URL}/member/${id}/favorite`, {method: 'POST'}),
		'member/favorite:DELETE': (id) => fetchResponse(`${BASE_URL}/member/${id}/favorite`, {method: 'DELETE'}),
		member,
		vote,
		committee,
		mapping,
		proPub,
	}
})(process.env.VUE_APP_ELLIS_API_BASE_URL)
