<template>
	<div class="am-collapsible" ref="collapsible"
		v-am-collapsible="{header, content, readMore, minHeight}">
		<div ref="header" class="header" @click="() => collapsed = !collapsed">
			<slot name="header"></slot>
		</div>
		<hr>
		<div ref="content" class="content">
			<slot name="content"></slot>
		</div>
		<div v-if="minHeight" class="footer">
			<button class="mini" ref="readMore" @click="() => collapsed = !collapsed">
				Read {{collapsed ? 'More' : 'Less'}} &nbsp;&nbsp;
				<i :class="['lni lni-chevron-up', {collapsed}]"/>
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import {computed, ref, watch} from 'vue'
import AmCollapsible from './amCollapsible'
export default {
	name: 'am-collapsible',
	directives: {AmCollapsible},
	props: {
		minHeight: {type: Number},
	},
	setup(p) {
		const collapsed = ref(true)
		return {
			header: ref(null),
			content: ref(null),
			readMore: ref(null),
			collapsed,
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'
.am-collapsible {
	position relative
	// &.content-fits {
	// 	.footer {
	// 		button {
	// 			display: none
	// 		}
	// 	}
	// }
	.content {
		overflow: hidden
	}
	.footer {
		absPos(auto, 0, 0, 0)
		z-index: 100
		flexXY(center, flex-end)
		wh(100%, 48px)
		background: linear-gradient(0deg, #f3f4f5, transparent)
		border-bottom: 1px solid #ddd
		button {
			absPos(50%, auto, auto, 50%)
			transform: translate(-50%, -50%)
			margin-top: 24px
			i.lni {
				transition: 0.4s all ease
				&.collapsed {
					transform: rotate(180deg)
				}
			}
		}
	}
}
</style>