import {createApp } from 'vue'
import router from './router'
import App from './App.vue'
import store from './vuex'
import registerComponents from 'src/components/ui'

import '../public/fonts/LineIcons.css'

const app: any = createApp(App)
	.use(router)
	.use(store)

registerComponents(app)

app.mount('#app')
