
import { defineComponent, ref } from 'vue'
import useGoogleAuth from '../components/user/useGoogleAuth'

export default defineComponent({
	name: 'Home',
	setup() {
		const signIn = ref(null)
		const {isSignedIn, profile} = useGoogleAuth([signIn])

		function scrollTo(top: number) {
			window.scroll({top, behavior: 'smooth'})
		}

		return {
			signIn,
			isSignedIn,
			profile,
			scrollTo,
		}
},
});
