<template>
	<footer class="el-footer box">
		<div class="comments">
			<div>
				<div class="links">
					<h6 class="center">Information</h6>
					<ul>
						<li><router-link to="/about">
							<i class="lni lni-bulb"/>&nbsp;&nbsp;
							About the Ellis Team
						</router-link></li>
						<li><router-link to="/privacy-policy">
							<i class="lni lni-shield"/>&nbsp;&nbsp;
							Privacy Policy
						</router-link></li>
						<li><router-link to="/contact">
							<i class="lni lni-phone"/>&nbsp;&nbsp;
							Contact Us
						</router-link></li>
					</ul>
				</div>
			</div>

			<div>
				<div class="credits">
					<h6 class="center">Acknowledgements</h6>
					<p>Ellis would not be possible without great work by the following organizations:</p>
					<div class="orgs">
						<a href="https://www.propublica.org/" target="_blank">
							<img class="invert" src="/img/propublica-logo.png" alt="ProPublica">
						</a>
						<a href="https://www.govinfo.gov/" target="_blank">
							<img class="govinfo" src="/img/govinfo-logo.png" alt="Govinfo">
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="social">
			<a href="https://www.facebook.com/ellisproj" target="_blank"><i class="lni lni-facebook"/></a>
			<a href="https://twitter.com/project_ellis" target="_blank"><i class="lni lni-twitter"/></a>
		</div>
	</footer>
</template>

<script lang="ts">
export default {
	name: 'el-footer'
}
</script>

<style lang="stylus" scoped>
@require '~style/mixins.styl'
@require '~style/variables.styl'

.el-footer {
	flexXY(space-between, center)
	flex-direction: column
	$c = desaturate(lighten($c-bg, 40%), 60%)

	color: $c-nav-text
	background: $c-footer-bg
	div.comments {
		margin-top: 24px
		min-width: 100%
		display: grid
		grid-template-columns: 1fr 1fr
		& > div {
			flexXY(center, flex-start)
			& > div {
				width: 300px
				margin-bottom: 24px
				img {
					max-width: 120px
					opacity: 0.3
					mix-blend-mode: hard-light
					&.invert {
						filter: invert(1)
					}
				}
			}
		}
		ul {
			li {
				margin-left: 0
			}
		}
		h1, h2, h3, h4, h5, h6 {
			border-bottom: 1px solid $c-nav-text
		}
		p, span, a {
			margin: 12px 0
			font-size: 0.85em
		}

		.credits {
			.orgs {
				flexXY(center)
				flex-wrap: wrap
				& > * {
					margin: 4px 8px
				}
			}
		}
	}

	.social {
		flex(center)
		& > * {
			margin: 0 8px
		}
		i.lni {
			padding: 4px
			font-size: 1.5rem
		}
	}
}

@media screen and (max-width: 790px) {
	.el-footer div.comments {
		grid-template-columns: 100%
	}
}
</style>