import {merge} from '@npm/lodash'

const authHeaders = {}

/**
 * Assigns Authorization header to all future requests
 * @param token the JWT token
 */
export function setAuthHeaders(token) {
	if(!token) delete authHeaders['Authorization']
	else authHeaders['Authorization'] = `Bearer ${token}`;
	(window as any)._token = token
}

const safeFetch = (url, options, formatFn = (r) => r.json()) => fetch(url, options).then((r) => {
	if (!r.ok) {
		console.error(`[Http] Error encountered: ${r.status} - ${r.statusText}`)
		throw new Error(r.statusText)
	}
	try {
		return formatFn(r)
	} catch (ex) {
		console.error(ex)
	}
})

const fetchJson = (url, options = {}) => safeFetch(url, merge(options, {
	headers: {
		...authHeaders,
		'Content-Type': 'application/json',
		'Accept': 'application/json',
	}
}), (r) => r.json())

const fetchText = (url, options = {}) => safeFetch(url, merge(options, {
	headers: {
		...authHeaders,
	}
}), (r) => r.text())

const fetchHTML = (url, options = {}) => safeFetch(url, merge(options, {
	headers: {
		...authHeaders,
		'Content-Type': 'text/html',
	}
}), (r) => r.text())

const fetchResponse = (url, options = {}) => safeFetch(url, merge(options, {
	headers: {
		...authHeaders,
		'Content-Type': 'application/json',
	}
}), (r) => r)

export {
	fetchResponse,
	fetchJson,
	fetchText,
	fetchHTML,
}
