
import Cookies from 'js-cookie'
import {computed, provide, onMounted, watch, ref} from 'vue'
import Mandala from 'mandala-js'
import api from './services/EllisService'
import useGoogleAuth from './components/user/useGoogleAuth'
import ElHeader from 'src/components/el-header.vue'
import ElFooter from 'src/components/el-footer.vue'
import ElNavigator from 'src/components/nav/el-navigator.vue'
import ElWelcome from 'src/components/el-welcome.vue'
import Home from 'src/views/Home.vue'
import { useStore } from 'vuex'

export default {
	components: {Home, ElHeader, ElFooter, ElNavigator, ElWelcome},
	computed: {
		isUnauthenticatedRoute() {
			return ['privacy', 'about', 'contact'].includes(this.$route.name)
		},
	},
	setup(props, ctx) {
		provide('api', api)
		const store = useStore()
		const profile = computed(() => store.state.profile)
		const {isSignedIn, isLoading} = useGoogleAuth()

		Cookies.set('ellis', Cookies.get('ellis') || JSON.stringify({
			dismissed: false,
		}), {expires: 3})
		const donate = JSON.parse(Cookies.get('ellis'))

		const donateShowFn = (hidden) => setTimeout(() => {
			hidden.value = (!profile.value || donate.dismissed)
		}, 5000)
		const donateDismiss = () => Cookies.set('ellis', {
			...donate,
			dismissed: true
		}, {expires: 3})

		onMounted(() => {
			new Mandala([
				{uri: '/data/mandala/load'},
			], {parentId: 'loading'})
		})

		return {
			isSignedIn,
			isLoading,
			donateShowFn,
			donateDismiss,
			store,
		}
	},
}
