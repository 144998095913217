
import {computed, ref, watch} from 'vue'
import AmCollapsible from './amCollapsible'
export default {
	name: 'am-collapsible',
	directives: {AmCollapsible},
	props: {
		minHeight: {type: Number},
	},
	setup(p) {
		const collapsed = ref(true)
		return {
			header: ref(null),
			content: ref(null),
			readMore: ref(null),
			collapsed,
		}
	},
}
