<template>
	<header id="top" class="el-header box">
		<router-link to="/"><h2 class="m0 em">Ellis</h2></router-link>
		<nav>
			<ul class="inline">
				<li v-if="isSignedIn">
					<small>Congress&nbsp;</small><select class="congress-selector" :value="congress" @change="onCongressChange">
						<option v-for="c in [117, 116, 115, 114]" :value="c" :key="c">
							{{c}}
						</option>
					</select>
				</li>
				<li v-if="isSignedIn"><small><a @click="signOut">Sign Out</a></small></li>
				<li v-else>
					<button ref="signInEl">
						<i class="lni lni-google" style="margin-right: 12px"/>
						<small>Sign In</small>
					</button>
				</li>
			</ul>
		</nav>
	</header>
</template>

<script lang="ts">
import {CURRENT_CONGRESS} from 'domain/Constants'
import useGoogleAuth from './user/useGoogleAuth'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
	name: 'el-header',
	setup() {
		const {profile, isSignedIn, signInEl, signOut} = useGoogleAuth()
		const store = useStore()

		const congress = computed(() => store.state.prefs.congress)
		function onCongressChange(e) {
			store.commit('prefs/setCongress', (parseInt(e.target?.value.trim())) || CURRENT_CONGRESS)
		}

		return {
			profile,
			isSignedIn,
			signInEl,
			signOut,
			congress,
			onCongressChange,
		}
	}
}
</script>

<style lang="stylus" scoped>
@require '~style/mixins.styl'
@require '~style/variables.styl'

.el-header {
	color: $c-text-bg
	background: linear-gradient(90deg, $c-nav-bg, 70%, lighten($c-bg, 10%))
	flexXY(space-between, center)

	& > a {
		text-decoration: none
		color: inherit
	}

	nav {
		button {
			neu(lighten($c-nav-bg, 0%), 0.9, $c-nav-bg, true)
			color: white
			border-radius: 20px
		}
		& > ul > li {
			cursor: pointer
		}
	}


	button.sign-up {
		color: $c-bg
		font-weight: bold
		background: $c-primary-1
	}

	select {
		background: none;
		padding: 0.25rem 0.5rem;
		border: 1px solid lighten($c-bg, 20%);
		border-radius: 1rem;
		color: $c-primary-1;
		option {
			color: black;
		}
	}
}
</style>