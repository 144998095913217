<template>
	<div class="el-navigator box">
		<el-user class="user"/>
		<nav :class="!profile ? 'disabled' : ''">
			<ul>
				<li><router-link to="/">
					<i class="lni lni-home"/>Home
				</router-link></li>
				<li><router-link to="/bills">
					<i class="lni lni-library"/>Bills
				</router-link></li>
				<!-- <li><router-link to="/members">
					<i class="lni lni-users"/>Members</router-link></li> -->
				<!-- <li><router-link to="/news">
					<i class="lni lni-comments"/>News</router-link></li> -->
			</ul>
		</nav>
	</div>
</template>

<script lang="ts">
import {computed} from 'vue'
import ElUser from 'src/components/user/el-user.vue'
import { useStore } from 'vuex'

export default {
	name: 'el-navigator',
	components: {ElUser},
	setup() {
		const store = useStore()
		const profile = computed(() => store.state.profile)

		return {
			profile,
		}
	}
}
</script>

<style lang="stylus" scoped>
@require '~style/mixins.styl'
@require '~style/variables.styl'

.el-navigator {
	flexXY(flex-start)
	min-width: 150px
	flex-direction: column
	background: $c-nav-bg
	color: $c-text-bg
}

nav {
	opacity: 1
	&.disabled {
		opacity: 0.4
		pointer-events: none
	}
}

nav > ul > li {
	padding: 1em 0
	a {
		padding: 6px 12px
		padding-left: 12px
		color: inherit
		text-decoration: none
		border-left: 4px solid transparent
		transition: 0.3s all ease
		&.router-link-active {
			border-left-color: $c-primary-1
		}
	}
	i.lni {
		font-size: 1.3rem
		margin-right: 12px
		color: $c-primary-1
	}
}

@media screen and (max-width: 600px) {
	.user {
		display: none
	}
	.el-navigator {
		background: linear-gradient(90deg, $c-bg, 70%, lighten($c-bg, 5%))
	}
}
</style>