
import {computed} from 'vue'
import ElUser from 'src/components/user/el-user.vue'
import { useStore } from 'vuex'

export default {
	name: 'el-navigator',
	components: {ElUser},
	setup() {
		const store = useStore()
		const profile = computed(() => store.state.profile)

		return {
			profile,
		}
	}
}
