<template>
	<div class="el-user">
		<div class="user-circle chip">
			<img v-if="isSignedIn" class="chip profile" :src="profile?.imageUrl"/>
		</div>
	</div>
</template>

<script lang="ts">
import useGoogleAuth from './useGoogleAuth'
export default {
	name: 'el-user',
	setup() {
		const {isSignedIn, profile} = useGoogleAuth()

		return {
			isSignedIn,
			profile,
		}
	},
}
</script>

<style lang="stylus" scoped>
@require '~style/mixins.styl'
@require '~style/variables.styl'

.el-user {
	flexXY(center)
	width: 100%
	margin-bottom: 24px
	div.user-circle {
		circle(70px)
		neu($c-bg, 1.5)
		background: rgba(0, 0, 0, 0.2)
	}
}
</style>