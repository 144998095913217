export interface Member {
	member_id: string,
	[key: string]: any,
}

export interface Metrics {
	favorite?: boolean,
}

export interface MemberState {
	members: Record<string, Member>
	metrics: Record<string, Metrics>
}

export default {
	namespaced: true,
	state: () => ({
		members: {},
		metrics: {},
	}),
	getters: {
		member: (s: MemberState) => (id: string) => s.members[id],
		metrics: (s: MemberState) => (id: string) => s.metrics[id],
	},
	mutations: {
		save: (s: MemberState, m: Member) => {
			s.members[m.member_id] = m
			if(!s.metrics[m.member_id]) s.metrics[m.member_id] = {}
		},
		favorite: (s: MemberState, id: string) => {
			if(!s.metrics[id]) s.metrics[id] = {}
			s.metrics[id].favorite = true
		},
		unfavorite: (s: MemberState, id: string) => {
			if(!s.metrics[id]) s.metrics[id] = {}
			s.metrics[id].favorite = false
		},
	},
}