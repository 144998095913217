<template>
	<div class="am-banner" :class="{closed}">
		<slot></slot>
		<div class="dismiss" @click="dismiss">
			<i class="lni lni-close"></i>
		</div>
	</div>
</template>

<script lang="ts">
import {ref, onMounted, Ref} from 'vue'

export default {
	name: 'am-banner',
	props: {
		showFn: {type: Function, default: (closed) => setTimeout(() => closed.value = false, 2000)}
	},
	setup(p: {showFn: (closed: Ref<boolean>) => void}, {emit}) {
		const closed = ref(true)
		const dismiss = (e) => {
			closed.value = true
			emit('dismiss')
		}

		onMounted(() => p.showFn(closed))

		return {closed, dismiss}
	}
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.am-banner {
	absPos(0, auto, auto, auto)
	width: 100%
	background: $c-primary-1
	padding: 4px 24px
	flexXY(space-between, center)
	z-index: 1
	transition: 0.5s all ease
	font-size: 0.85rem
	&.closed {
		top: -50px
		opacity: 0
		pointer-events: none
	}

	.dismiss {
		flexXY(center)
		color: black
		cursor: pointer
		i {
			font-size: 0.80rem
		}
	}
}
</style>