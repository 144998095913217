
import {CURRENT_CONGRESS} from 'domain/Constants'
import useGoogleAuth from './user/useGoogleAuth'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
	name: 'el-header',
	setup() {
		const {profile, isSignedIn, signInEl, signOut} = useGoogleAuth()
		const store = useStore()

		const congress = computed(() => store.state.prefs.congress)
		function onCongressChange(e) {
			store.commit('prefs/setCongress', (parseInt(e.target?.value.trim())) || CURRENT_CONGRESS)
		}

		return {
			profile,
			isSignedIn,
			signInEl,
			signOut,
			congress,
			onCongressChange,
		}
	}
}
