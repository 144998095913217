
import {ref, onMounted, Ref} from 'vue'

export default {
	name: 'am-banner',
	props: {
		showFn: {type: Function, default: (closed) => setTimeout(() => closed.value = false, 2000)}
	},
	setup(p: {showFn: (closed: Ref<boolean>) => void}, {emit}) {
		const closed = ref(true)
		const dismiss = (e) => {
			closed.value = true
			emit('dismiss')
		}

		onMounted(() => p.showFn(closed))

		return {closed, dismiss}
	}
}
