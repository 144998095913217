import {useStore} from 'vuex'
import api from 'src/services/EllisService'
import { computed, onMounted, ref} from 'vue'
import {setAuthHeaders} from 'lib/http/Http'

const CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID

export default function useGoogleAuth(signInRefs: any[] = []) {
	const store = useStore()
	const signInEl = ref(null)
	const auth2 = ref(null)
	const profile = computed(() => store.state.profile)
	const isSignedIn = computed(() => !!store.state.profile.last_sign_in)
	const isLoading = ref(true)
	const loadingTimeout = setTimeout(() => {
		isLoading.value = false
	}, 3000)

	function initAuth(options?) {
		(window as any).gapi.load('auth2', () => {
			auth2.value = (window as any).gapi.auth2.init({
				client_id: options?.clientId || CLIENT_ID,
				cookiepolicy: 'single_host_origin',
			})

			auth2.value.isSignedIn.listen(signIn)
		})
	}
	initAuth()

	onMounted(async () => {
		// @ts-ignore
		window.gapi.load('auth2', () => {
			auth2.value.attachClickHandler(signInEl.value)
			signInRefs.forEach((ref) => auth2.value.attachClickHandler(ref.value))
		})
	})

	async function signIn() {
		!auth2.value && initAuth()
		const user = auth2.value.currentUser.get().getBasicProfile()
		const token = auth2.value.currentUser.get().getAuthResponse().id_token
		const profile = await api.user.signIn({token})
		setAuthHeaders(token)
		store.commit('saveProfile', {
			...profile,
			token,
			imageUrl: user.getImageUrl(),
		})
		profile.data.bills.forEach((id) => store.commit('bill/favorite', id))
		profile.data.members.forEach((id) => store.commit('member/favorite', id))
		clearTimeout(loadingTimeout)
		isLoading.value = false
		return store.state.profile
	}

	async function signOut() {
		!auth2.value && initAuth()
		auth2.value.signOut()
		location.reload()
	}

	return {
		isSignedIn,
		isLoading,
		profile,
		signIn,
		signInEl,
		signOut,
	}
}