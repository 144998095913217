<template>
	<li class="am-li">
		<slot></slot>
	</li>
</template>

<script lang="ts">
export default {
	name: 'am-li',
}
</script>

<style lang="stylus" scoped>
.am-li {

}
</style>