<template>
	<el-header class="span-col-3"/>
	<el-navigator/>
	<div class="container" :class="{isLoading}" :key="isLoading">
		<am-banner :showFn="donateShowFn" @dismiss="donateDismiss">
			<div class="donate-message">
				<span>Ellis improves through the power of individual contributions. No ads. Ever.</span>&nbsp;&nbsp;&nbsp;
				<a href="https://www.buymeacoffee.com/ellis" target="_blank"><button class="small outline"><i class="lni lni-coffee-cup"></i>&nbsp; Buy us a coffee.</button></a>
			</div>
		</am-banner>
		<div>
			<router-view v-show="(isSignedIn && !isLoading) || (isUnauthenticatedRoute && !isLoading)"/>
		</div>
		<cam-box class="loading" p="2" flex="center" dir="column" v-show="isLoading">
			<h1 class="em center">Ellis</h1>
			<div class="mandala" id="loading"></div>
		</cam-box>
		<Home v-if="!isSignedIn && !isLoading && !isUnauthenticatedRoute"/>
	</div>
	<div class="toolbar">
	</div>
	<el-footer class="span-col-3"/>
</template>

<script lang="ts">
import Cookies from 'js-cookie'
import {computed, provide, onMounted, watch, ref} from 'vue'
import Mandala from 'mandala-js'
import api from './services/EllisService'
import useGoogleAuth from './components/user/useGoogleAuth'
import ElHeader from 'src/components/el-header.vue'
import ElFooter from 'src/components/el-footer.vue'
import ElNavigator from 'src/components/nav/el-navigator.vue'
import ElWelcome from 'src/components/el-welcome.vue'
import Home from 'src/views/Home.vue'
import { useStore } from 'vuex'

export default {
	components: {Home, ElHeader, ElFooter, ElNavigator, ElWelcome},
	computed: {
		isUnauthenticatedRoute() {
			return ['privacy', 'about', 'contact'].includes(this.$route.name)
		},
	},
	setup(props, ctx) {
		provide('api', api)
		const store = useStore()
		const profile = computed(() => store.state.profile)
		const {isSignedIn, isLoading} = useGoogleAuth()

		Cookies.set('ellis', Cookies.get('ellis') || JSON.stringify({
			dismissed: false,
		}), {expires: 3})
		const donate = JSON.parse(Cookies.get('ellis'))

		const donateShowFn = (hidden) => setTimeout(() => {
			hidden.value = (!profile.value || donate.dismissed)
		}, 5000)
		const donateDismiss = () => Cookies.set('ellis', {
			...donate,
			dismissed: true
		}, {expires: 3})

		onMounted(() => {
			new Mandala([
				{uri: '/data/mandala/load'},
			], {parentId: 'loading'})
		})

		return {
			isSignedIn,
			isLoading,
			donateShowFn,
			donateDismiss,
			store,
		}
	},
}
</script>

<style>
:host {
	--cam-unit: 8px;
	--cam-font-family: 'Avenir Next', sans-serif;
}
</style>
<style lang="stylus" src="style/app.styl"></style>
<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.container {
	position: relative
	&.isLoading {
		background: $c-footer-bg
		overflow: hidden
	}
}

.loading {
	overflow: hidden
	min-height: calc(600px)
	transition: 0.3s all ease
	.mandala {
		position absolute
		padding-top: 80px
		padding-right: 60px
	}
	color: desaturate(lighten($c-bg, 70%), 60%)
}

.donate-message {
	flexXY(flex-start, center)
	button {
		color: white
	}
}
</style>