import { Vote } from "domain/vote/Vote";
import { voteKey } from "src/util/vote";

export interface VoteState {
	votes: Record<string, any>,
}

export default {
	namespaced: true,
	state: () => ({
		votes: {}
	}),
	getters: {
		read: (state: VoteState) => (key: string) => state.votes[key],
	},
	mutations: {
		saveVoteFromUrl: (state: VoteState, vote: Vote) => {
			state.votes[voteKey(vote)] = vote
		}
	},
}