function fullHeight(el: HTMLElement) {
	const rules = [
		'margin-top','margin-bottom',
		'border-top', 'border-bottom',
		'padding-top', 'padding-bottom',
		'height',
	]

	const style = window.getComputedStyle(el)
	return rules.map(k => parseInt(style.getPropertyValue(k), 10))
		.filter((v) => isNaN(v) ? 0 : v)
		.reduce((sum, i) => sum + i, 0)
}

function fullHeightOfChildren(content: HTMLElement) {
	console.log(content)
	const children = Array.from(content.children) as HTMLElement[] || []
	const negMargin = (children.length - 1) * maxVertMargin(children?.[0]) * -1
	const height = children.reduce((sum: number, c: HTMLElement, i) => {
		return sum + fullHeight(c)
	}, negMargin) || 0
	return height
}

function maxVertMargin(el: HTMLElement) {
	if(!el) return 0
	const rules = ['margin-top', 'margin-bottom']
	const style = window.getComputedStyle(el)
	return Math.max(...rules.map((r) => parseInt(style.getPropertyValue(r), 10)))
}

function createCollapsible(el: HTMLElement, {header, content, minHeight, collapsed}) {
	collapsed = collapsed || false
	const renderedContentHeight = fullHeightOfChildren(content)
	if(renderedContentHeight < minHeight) {
		el.classList.add('content-fits')
	}

	const collapse = (ms: number) => {
		content.style.transition = `${ms / 1000}s all ease`
		content.style.height = `${minHeight}px`
		if(!minHeight) {
			setTimeout(() => content.style.opacity = '0', ms / 2)
		}
		el.classList.add('collapsed')
		return true
	}

	const expand = (ms: number) => {
		content.style.transition = `${ms / 1000}s all ease`
		content.style.opacity = '1'
		const height = fullHeightOfChildren(content)

		content.style.height = `${height}px`
		el.classList.remove('collapsed')
		return false
	}

	const toggle = (el, ms) => collapsed = collapsed ? expand(ms) : collapse(ms)

	collapsed ? collapse(0) : expand(0)
	return {
		toggle,
	}
}

export default (function amCollapsible() {
	const registry = new Map()

	function updated(el, binding, vnode) {
		if(!binding.value.header || !binding.value.content) return
		const header = binding.value.header
		const content = binding.value.content
		const readMore = binding.value.readMore
		const minHeight = binding.value.minHeight || 0

		const col = createCollapsible(el, {header, content, minHeight,
			collapsed: true
		})

		const expandCollapsible = (e) => col.toggle(content, 600)
		header.addEventListener('click', expandCollapsible)
		if(readMore) readMore.addEventListener('click', expandCollapsible)
		registry.set(el, expandCollapsible)
	}

	function beforeUpdate(el, binding) {
		const expandCollapsible = registry.get(el)
		binding.value.header.removeEventListener('click', expandCollapsible)
		if(binding.value.readMore) binding.value.readMore.removeEventListener('click', expandCollapsible)
	}

	return {
		updated,
		beforeUpdate,
	}
})()