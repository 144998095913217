
import useGoogleAuth from './useGoogleAuth'
export default {
	name: 'el-user',
	setup() {
		const {isSignedIn, profile} = useGoogleAuth()

		return {
			isSignedIn,
			profile,
		}
	},
}
