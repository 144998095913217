<template>
	<cam-box m="2" class="home">
		<article>
			<cam-box class="intro" flex="center" dir="column" p="8">
				<h3 class="em">The Ellis Project</h3>
				<cam-box flex="center" v-if="isSignedIn">
					<router-link to="/bills">
						<button><i class="lni lni-library"/>&nbsp; Bill Dashboard</button>
					</router-link>
				</cam-box>
				<p>The Ellis Project assists you to lead a more educated civic life.
					Use Ellis to engage with issues you care about, bypass misinformation, and become better informed.</p>
			</cam-box>
			<hr>
			<br><br><br>
			<h4>How It Works</h4>
			<p>Right now, Ellis is focused on connecting you with bills you're interested in, like this one by the late, great John Lewis:</p>
			<div class="img-fade-container">
				<img src="/img/ellis-bill.png"/>
			</div>
			<br><br><br>
			<p>View the full text of the bill and it's sponsor, see the sponsor's voting record, and learn more about their recent votes.</p>

			<cam-box class="team" flex="center" dir="column" p="8">
				<i class="lni lni-cogs" style="font-size: 3em"></i>
				<p align="center"> We are a small team but always adding to Ellis' capabilities. Check in often for updates as we add them!</p>
				<div class="social">
					<a href="https://www.facebook.com/ellisproj" target="_blank" title="Facebook"><i class="lni lni-facebook"/></a>
					<a href="https://twitter.com/project_ellis" target="_blank" title="Twitter"><i class="lni lni-twitter"/></a>
				</div>
			</cam-box>

			<cam-box v-if="!isSignedIn" class="cta" flex="center" dir="column" p="8">
				<h4>Ready to get started?</h4>
				<p align="center">Log in with your Google Account to get started.</p>
				<button ref="signIn" @click="scrollTo(0)">
					<i class="lni lni-google" style="margin-right: 12px"/>
					<small>Sign In</small>
				</button>
				<br>
				<router-link to="/privacy-policy"><small>Our Privacy Policy</small></router-link>
			</cam-box>
		</article>
	</cam-box>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import useGoogleAuth from '../components/user/useGoogleAuth'

export default defineComponent({
	name: 'Home',
	setup() {
		const signIn = ref(null)
		const {isSignedIn, profile} = useGoogleAuth([signIn])

		function scrollTo(top: number) {
			window.scroll({top, behavior: 'smooth'})
		}

		return {
			signIn,
			isSignedIn,
			profile,
			scrollTo,
		}
},
});
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.home {
	flexXY(center)
}

article {
	position: relative
	max-width: 50rem
	img {
		wh(100%)
		filter: brightness(1.12)
	}
	.img-fade-container {
		position relative
		wh(100%)
	}
	.img-fade-container::after {
		content: ''
		absPos(0, 0, 0, 0)
		background: linear-gradient(90deg, transparent 70%, #fafafa), linear-gradient(0deg, #fafafa, transparent 30%)
		z-index: 1
	}

	.intro {

	}
	.team {
		background: linear-gradient(30deg, hue(lighten($c-bg, 20%), 190), $c-bg 70%)
		color: $c-text-bg
		.social {
			flexXY(center)
			& > * {
				flexXY(center)
				neu(hue(lighten($c-bg, 20%), 190), 1)
				margin: 16px
				font-size: 1.4em
				circle(2.4em)
				color: white
				background: lighten($c-bg, 13%)
				transition: 0.3s all ease
				&:hover {
					background: hue(lighten($c-bg, 30%), 190)
				}
			}
		}
	}

	button {
		neu(lighten(#e6e8e9, 0%), 0.9)
		color: $c-bg
		border-radius: 20px
		small {
			font-weight: bold
			font-size: 0.85em
		}
	}
}
</style>
