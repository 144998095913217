const calcSession = (date) => {
	const match = /(\d+)/g.exec(date)
	return match ? ((parseFloat(match[0]) % 2 === 0) ? '2' : '1') : null
}
const voteKey = (vote) => {
	if(!vote.congress) throw new Error('Cannot calculate voteKey. Missing "congress" property.')
	if(!vote.chamber) throw new Error('Cannot calculate voteKey. Missing "chamber" property.')
	if(!vote.date) throw new Error('Cannot calculate voteKey. Missing "date" property.')
	if(!vote.roll_call) throw new Error('Cannot calculate voteKey. Missing "roll_call" property.')

	return `${vote.congress}.${vote.chamber.toLowerCase()}.${calcSession(vote.date) || 'session'}.${vote.roll_call}`
}

export {
	voteKey,
}