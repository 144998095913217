export interface Bill {
  bill_id: string,
  [key: string]: any,
}

export interface Metrics {
  favorite?: boolean,
}

export interface BillState {
  bills: Record<string, Bill>,
  metrics: Record<string, Metrics>,
}

export default {
  namespaced: true,
  state: () => ({
    bills: {},
    metrics: {},
  }),
  getters: {
    bill: (s: BillState) => (id: string) => s.bills[id],
    metrics: (s: BillState) => (id: string) => s.metrics[id] ? s.metrics[id] : {},
    favorites: (s: BillState) => Object.entries(s.metrics).filter(([id, metrics]) => metrics.favorite).map(([id, _]) => id),
  },
  mutations: {
    save: (s: BillState, b: Bill) => {
      s.bills[b.bill_id] = b
      if(!s.metrics[b.bill_id]) s.metrics[b.bill_id] = {}
    },
    favorite: (s: BillState, id: string) => {
      if(!s.metrics[id]) s.metrics[id] = {}
      s.metrics[id].favorite = true
    },
    unfavorite: (s: BillState, id: string) => {
      if(!s.metrics[id]) s.metrics[id] = {}
      s.metrics[id].favorite = false
    },
  },
}
